@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1600px;

.ytip-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $sm) {
    margin: auto;
  }

  @media (min-width: $xxl) {
    padding: 0;
  }

  @media (max-width: $md) {
    border-radius: 0;
    margin-bottom: 32px;
  }
}

.your-time-is-precious {
  width: 100%;
  height: 420px;
  padding: 0 15px;
  margin-top: 12px;

  @media (min-width: $sm) {
    height: calc(100vw * 0.15);
    min-height: fit-content;
    padding: 0;
    margin-top: 0;
  }

  @media (min-width: $lg) {
    height: calc(100vw * 0.17);
  }

  &__container {
    width: 100%;
    height: 100%;
    background-image: url('/img/trip-splash-mobile.png');
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: $color-craft;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $sm) {
      background-image: url('/img/trip-splash.png');
      background-size: cover;
      border-radius: 24px;
    }
  }

  &__grid {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    padding: 40px;

    @media (min-width: $sm) {
      padding-top: calc(3vw);
      width: 75%;
      max-width: 1100px;
      display: grid;
      gap: 0;
      grid-template-columns: 50% 50%;
    }

    @media (min-width: $xl) {
      padding-top: calc(4vw);
    }
  }

  &__grid__headers {
    gap: 6px;
    display: flex;
    flex-direction: column;

    @media (min-width: $sm) {
      padding-right: 20px;
      gap: 0;
    }

    @media (min-width: $lg) {
      padding-right: 60px;
    }
  }

  &__grid__body {
    font-weight: 400 !important;
  }
}
