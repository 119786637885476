@import 'styles/variables';

.pricing-container {
  width: 100%;
  padding-bottom: 74px;
  padding-top: 52px;
  max-width: 1080px;
  margin: auto;
}

.destination-price-widget {
  padding-bottom: 20px;

  @media (max-width: 880px) {
    padding-bottom: 0;
  }
}

.text-container-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 52px;

  @media (max-width: 880px) {
    flex-direction: column;
    margin-top: 0;
    margin-left: 24px;
    margin-right: 24px;
  }
}

.text-container-left {
  flex-shrink: 0;
  @media (max-width: 880px) {
    width: 100%;
  }

  h2 {
    width: 250px;
    font-size: 24px !important;
  }

  h3 {
    color: $color-gray-6 !important;
    font-size: 16px !important;
  }
}

.text-container-right {
  max-width: 690px;

  hr {
    border-color: $color-gray-4;
    opacity: 1;
    border-width: 1px 0 0;
    border-style: solid;
  }

  flex-shrink: 1;
}

.dropdown {
  background-color: transparent;
  padding-bottom: 5px;
}

.dropdown-open {
  p {
    max-height: 100%;
  }
}

.dropdown-closed {
  p {
    max-height: 0;
  }
}

.dropdown-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-content {
  p {
    font-size: 14px;
    overflow: hidden;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    text-align: left;
  }
}

.craft-trip {
  width: 211px !important;
  height: 56px !important;
  border-radius: 40px !important;
  font-size: 18px !important;
  margin-top: 32px;
  text-decoration: none !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 880px) {
    width: 340px !important;
  }
}

.where-your-money-goes {
  h2 {
    width: 250px;
    font-size: 24px !important;
  }

  p {
    font-weight: 500;
  }

  :global {
    .where-your-money-goes {
      &__content {
        padding-left: 6px;
        padding-right: 6px;
        margin-top: 14px;

        @media (min-width: 880px) {
          margin-top: 10px;
        }
      }

      &__content-container {
        display: flex;
        flex-direction: column;
        margin: 30px 0;
        gap: 30px;

        @media (min-width: 880px) {
          flex-direction: row;
          gap: 0;
          margin-top: 0;
        }
      }

      &__text {
        @media (min-width: 880px) {
          width: 50%;
        }
      }

      &__chart {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 880px) {
          order: 1;
          width: 50%;
          align-items: flex-start;
          margin: 0 30px;
        }
      }

      &__chart-img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__chart-label {
        position: absolute;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 32px;
        color: $color-gray-6;
        width: 50%;
        text-align: center;
        margin-bottom: 0 !important;
      }

      &__labels {
        display: flex;
        flex-direction: row;
        gap: 16px;

        @media (min-width: 880px) {
          flex-direction: column;
          gap: 0;
        }
      }

      &__labels-columns {
        width: 50%;

        @media (min-width: 880px) {
          width: 100%;
        }
      }

      &__labels-header-blue,
      &__labels-header-purple {
        font-size: 24px !important;
        margin-bottom: 4px !important;
      }

      &__labels-header-blue {
        color: #589fff !important;
      }

      &__labels-header-purple {
        color: $color-primary-purple !important;
      }

      &__toolbar {
        display: flex;
        flex-direction: row;
        gap: 13px;
        margin-top: 24px;

        @media (min-width: 880px) {
          flex-direction: column;
        }
      }

      &__toolbar-button {
        position: relative;
        padding: 0;
        background-color: #f5f0ff;
        width: 50%;

        @media (min-width: 880px) {
          width: 75%;
        }

        &--active {
          background-image: linear-gradient(45deg, #324bf9 11%, #9409ee 84%);
          background-color: #f5f0ff;
          border: none;
          border-radius: 26px;
          height: 34px;

          @media (min-width: 880px) {
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }

      &__toolbar-inner-button {
        height: 34px;
        font-weight: 600;
        color: $color-gray-7;
        border: 1px solid #e4d9fb;
        border-radius: 26px;
        display: flex;
        line-height: 2rem;
        background-color: #f5f0ff;
        padding-left: 19px;

        @media (max-width: 880px) {
          justify-content: center;
          justify-items: center;
          font-size: 14px;
          width: auto;
          padding-left: 16px;
          padding-right: 16px;
          white-space: nowrap;
        }

        &--active {
          background-color: #f5f0ff;
          margin: auto;
          height: 30px;
          border: none;
          align-self: center;

          @media (max-width: 880px) {
            font-size: 14px;
            width: calc(100% - 4px);
          }
        }
      }

      &__toolbar-inner-arrow {
        height: 0;
      }

      &__toolbar-inner-arrow-icon {
        display: none;

        @media (min-width: 880px) {
          &--active {
            position: absolute;
            display: block;
            right: 12px;
            top: 50%;
            height: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &__industry-note {
        h4 {
          color: #4695ff;
          font-size: 16px;
          margin-top: 20px;

          @media (min-width: 880px) {
            margin-top: 12px;
          }
        }

        p {
          font-size: 14px;
          font-weight: 400;
          margin-top: 8px;
        }
      }
    }
  }
}
