@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1600px;

.process-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  @media (min-width: $sm) {
    gap: 24px;
    width: 75%;
    max-width: 1100px;
  }
}

.process-header {
  width: 100%;
  text-align: center;
}

.panel-containers {
  padding: 0 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: $sm) {
    margin: 0;
    padding: 0;
    gap: 32px;
  }
}

.top-panel {
  @media (min-width: $sm) {
    width: 100%;
  }
}

.tabs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  row-gap: 5px;

  @media (min-width: $sm) {
    flex-direction: row;
    padding: 0;
    justify-content: space-between;
  }
}

.tab-button {
  background-color: rgba(240, 238, 255, 0.5);
  border-radius: 40px;
  height: 60px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  position: relative;

  h3 {
    color: $color-gray-7 !important;
  }

  .tab-button-number {
    opacity: 0.8;
  }

  @media (min-width: $sm) {
    width: 100%;
    max-width: 230px;
    height: 80px;
  }

  @media (min-width: $xl) {
    height: 90px;
    max-width: 280px;
    border-radius: 50px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: $xxl) {
    height: 100px;
    max-width: 312px;
    padding-left: 16px;
    padding-right: 16px;

    h3 {
      line-height: 32px;
    }
  }
}

.tab-button-active {
  @media (max-width: $sm) {
    animation: fadeBackground 0.5s ease-in-out forwards;
  }

  h3 {
    color: $color-gray-8 !important;
  }

  .tab-button-number {
    opacity: 1;
  }
}

@keyframes fadeBackground {
  from {
    background-color: rgba(240, 238, 255, 0.5);
  }
  to {
    background-color: rgba(240, 238, 255, 1);
  }
}

.tab-button-swipe {
  background-color: rgba(240, 238, 255, 1);
  width: 100%;
  height: 100%;
  border-radius: 40px;
  left: 0;
  position: absolute;

  @media (min-width: $xl) {
    border-radius: 50px;
  }

  &--right-1 {
    animation: swipeRight1 0.25s ease-in-out forwards;
  }
  &--left-1 {
    animation: swipeLeft1 0.25s ease-in-out forwards;
  }
  &--right-2 {
    animation: swipeRight2 0.5s ease-in-out forwards;
  }
  &--left-2 {
    animation: swipeLeft2 0.5s ease-in-out forwards;
  }
}

@keyframes swipeRight1 {
  from {
    margin-left: -100%;
    width: 0%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes swipeRight2 {
  from {
    margin-left: -200%;
    width: 100%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes swipeLeft1 {
  from {
    margin-left: 100%;
    width: 100%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes swipeLeft2 {
  from {
    margin-left: 200%;
    width: 100%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.tab-button-progress {
  height: 4px;
  width: 80%;
  background-color: $blue-light;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 10%;

  @media (min-width: $sm) {
    width: 70%;
    left: 15%;
  }
}

.tab-button-progress-inner {
  height: 100%;
  background: #324bf9;
  border-radius: 4px;
  width: 100%;
  animation: progressBar 5s ease-in-out forwards;
}

@keyframes progressBar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.tab-button-text {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 22px;
  margin-left: 22px;

  @media (min-width: $sm) {
    z-index: 1;
    column-gap: 10px;
    margin: 0 18px;
    text-align: left;
  }

  @media (min-width: $xxl) {
    column-gap: 14px;
    margin: 0 36px;
  }

  p {
    margin-bottom: 0px !important;
    font-size: 38px;
    font-weight: 800;
    line-height: 100%;

    @media (min-width: $md) {
      font-size: 60px;
    }

    @media (min-width: $xxl) {
      font-size: 70px;
    }
  }
}

.tab-button-number {
  background: linear-gradient(45deg, #324bf9 11%, #9409ee 84%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 3px;
}

.tab-button-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.bullet-icon {
  flex-shrink: 0;
}

.bottom-panel {
  flex-shrink: 1;
  width: 100%;
  display: none;

  @media (min-width: $sm) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: $lg) {
    display: grid;
    grid-template-columns: 60% 40%;
  }

  @media (min-width: $xl) {
    padding: 0 2rem;
  }
}

.bottom-panel-text {
  animation: fadeIn 0.25s ease-in-out forwards;
  position: relative;

  ul {
    list-style-type: none !important;
    padding-left: 0px !important;
    padding-right: 84px;

    @media (min-width: $sm) {
      padding-right: 64px;
    }

    @media (min-width: $xl) {
      padding-right: 84px;
    }
  }

  li {
    display: flex;
    align-items: flex-start;
    column-gap: 16px;
    padding-top: 20px;

    @media (min-width: $sm) {
      padding-top: 10px;
    }

    @media (min-width: $xl) {
      padding-top: 20px;
    }
  }

  p {
    color: $color-gray-7;
    font-size: 16px;
  }

  h3 {
    margin-bottom: 0px !important;
    margin-top: 32px !important;
    padding-bottom: 4px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mobile-panel-text {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ul {
    list-style-type: none !important;
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  li {
    display: flex;
    align-items: flex-start;
    column-gap: 8px;
    padding-top: 8px;
  }

  p {
    color: $color-gray-7;
    font-size: 16px;
  }

  h3 {
    margin-bottom: 0px !important;
    margin-top: 32px !important;
    padding-bottom: 4px;
  }

  @media (min-width: $sm) {
    display: none;
  }
}

.mobile-panel-text-selected {
  max-height: 500px;
}

.video {
  margin-top: 20px;

  @media (min-width: $lg) {
    margin-top: 16px;
  }
}

.craft-trip {
  height: 56px !important;
  border-radius: 40px !important;
  font-size: 18px !important;
  text-decoration: none !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &--desktop {
    position: absolute;
    bottom: 0;
    width: 211px;

    @media (max-width: $lg) {
      position: relative;
      margin: 32px auto 12px auto;
      margin-top: 32px;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  &--mobile {
    width: 100%;
    margin-top: 32px;

    @media (min-width: $xl) {
      display: none;
    }
  }
}
