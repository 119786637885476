@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1600px;

.faq-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 10px;

  @media (min-width: $sm) {
    gap: 24px;
    width: 75%;
    max-width: 1100px;
  }
}

.faq-container__header {
  width: 100%;
  text-align: center;
}

.text-container-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;

  @media (max-width: $md) {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }
}

.text-container-right {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (min-width: $sm) {
    gap: 10px;
  }
}

.dropdown {
  background-color: transparent;
  width: 100%;
  height: 48px;
  padding-left: 0px !important;
  padding-right: 0px !important;

  @media (min-width: $sm) {
    height: 56px;
  }

  @media (min-width: $xl) {
    height: 64px;
  }
}

.dropdown-open {
  gap: 20px;
  margin: 20px 0;

  @media (min-width: $sm) {
    margin-top: 25px 0;
  }

  p {
    max-height: 300px;

    @media (max-width: $md) {
      max-height: 1000px;
    }
  }
}

.dropdown-closed {
  p {
    max-height: 0;
    margin: 0 !important;
  }
}

@keyframes fadeBackground {
  from {
    background-color: rgba(240, 238, 255, 0.5);
  }
  to {
    background-color: rgba(240, 238, 255, 1);
  }
}

.dropdown-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  height: 100%;
  background-color: rgba(240, 238, 255, 0.5);
  border-radius: 32px;
  padding: 0 19px 0 26px;

  &--open {
    animation: fadeBackground 0.5s ease-in-out forwards;
  }
}

.dropdown-content {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  @media (min-width: $sm) {
    padding: 0 1rem;
  }

  p {
    overflow: hidden;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    text-align: left;
    margin-bottom: 0 !important;
  }
}

.craft-trip {
  width: 100%;
  height: 56px !important;
  border-radius: 40px !important;
  font-size: 18px !important;
  text-decoration: none !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.inclusions-exclusions {
  width: 100%;
  margin-top: 4px;

  p:not(:first-child) {
    margin-top: 16px !important;
  }
}
