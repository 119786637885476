.section-press-bar {
  position: relative;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
  transition: opacity 200ms ease;
  transition: opacity 200ms ease;

  :global .tint {
    background-color: #f5f5f5;
  }

  :global .logo-img {
    margin-right: 65px;
    height: 40px;

    img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
    }
  }

  :global .slide-wrap-css {
    display: flex;
    margin-right: -20px;
    margin-left: -20px;
    padding: 10px 40px 60px 50px;
    align-items: center;
    max-width: 100%;
    width: 100%;
    height: 40px;

    .mover {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 3200px;
      height: 30px;
      transform: translate3d(0, 0, 0);
      animation: moveSlideshow 25s linear infinite;

      @media screen and (min-width: 1441px) {
        width: 4400px;
        height: 40px;
      }
    }

    img.logo-img {
      height: 30px;
      margin-right: 39px;
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;

      @media screen and (min-width: 1441px) {
        height: 40px;
        margin-right: 65px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0 45px;
  }

  @media screen and (max-width: 767px) {
    padding: 25px;
  }

  @media screen and (max-width: 479px) {
    padding: 0 25px;
  }
}
