@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;

.container-headings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1860px;
  padding: 0 108px;
  margin-bottom: 16px;
  margin: 0 auto;

  @media screen and (max-width: $md) {
    padding: 0 48px;
  }

  @media screen and (max-width: $sm) {
    padding: 0 16px;
    flex-direction: column;
    text-align: center;

    > :nth-child(1) {
      text-align: center;
      margin: 0 auto;
    }
  }

  :global {
    .subheading {
      font-size: 16px;

      @media screen and (max-width: $md) {
        margin-top: -8px!important;
        font-size: 14px;
      }
    }

    .icon-wrapper {
      display: flex;
      width: 48px;
      height: 48px;
      align-items: center;
      justify-content: center;

      background-color: #f3f1f6;
      border-radius: 50%;

      .google-icon {
        width: 25px;
        height: 25px;
      }
    }

    .google-component {
      display: flex;
      gap: 16px;

      @media screen and (max-width: $sm) { 
        margin-top: 16px;
      }

      .section {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }

      .overall-rating {
        color: #666;
        font-weight: bold;
        font-size: 18px;
        margin-right: 6px;
      }

      .reviews-stats {
        margin-top: -2px;
      }

      .reviews-link {
        margin-top: -2px;

        a {
          font-size: 14px;
        }
        
        .total-reviews {
          font-size: 14px;
          padding-left: 5px;
        }
      }

      .double-height {
        grid-row: span 2;
        background-color: #2ecc71;
      }
    }
  }
}

.review-wrapper {
  img {
    position: absolute;
    top: 10px;
    width: 26px;
    left: 49%;
    transform: translateX(-50%);
  }
}

.review {
  display: grid;
  grid-template-rows: auto auto auto; // Each row for text, author, source, ratings
  border-radius: 12px;
  box-shadow: 0px 2px 6px #8A85971A;
  border: 1px solid #5C57661A;
  padding-top: 20px;
  margin-top: 20px;
  width: 380px;
  height: 246px;

  // Styling for the text section
  > :nth-child(1) {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 120px;
    padding: 24px 24px 0px 24px;
  }

  // Styling for the rating section
  > :nth-child(2) {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: bold;
    color: #FEB721;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  // Styling for the author / source section
  > :nth-child(3) {
    font-size: 14px;
    text-align: center;
    color: $color-gray-7;
    margin-bottom: 6px;

    // Styling for the author
    > :nth-child(1) {
      color: $color-gray-8;
    }
  }
}