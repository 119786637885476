@import 'styles/variables';

$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;

.swiper-container {
    width: 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 10px 30px;
    gap: 10px;
    font-size: 16px !important;
    line-height: 25px !important; 
  
    @media screen and (max-width: $sm) {
      width: 120px;
      font-size: 14px !important;
      line-height: 25px !important;
      grid-template-rows: 5px 18px;
      padding-bottom: 10px;
      overflow: hidden;
    }
  }
  
  .popular-now {
    color: #342F41;
    font-size: 12px !important;
    padding-top: 3px;
  
    @media screen and (max-width: $sm) {
      padding-top: 0;
    }
  }
  
  .swiper-destination {
    color: #342F41;
    font-size: 16px;
    font-weight: 600;
  
    @media screen and (max-width: $sm) {
      font-size: 14px;
    }
  }
  
  .search-button {
    margin-left:5px;
    width: 24px;
    height: 24px;
    border-radius: 29px;
    background-image: url('/webflow/images/search-button.svg');
    background-position: 48% 48%, 0 0;
    background-size: 18px, auto;
    background-repeat: no-repeat, repeat;
    transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    flex-shrink: 0;

    @media #{$breakpoint-md-up} {
      width: 48px;
      height: 48px;
      margin-left:0;
      background-size: 20px, auto;
    }
  }