@import 'styles/variables';

.local-experts-container {
  width: 100%;
  padding-bottom: 74px;
  max-width: 1080px;
  margin: auto;
}

.text-container-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 52px;

  @media (max-width: 880px) {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }
}

.text-container-left {
  flex-shrink: 0;

  h2 {
    width: 250px;
    font-size: 24px !important;
  }
}

.text-container-right {
  max-width: 690px;

  hr {
    border-color: $color-gray-4;
    opacity: 1;
    border-width: 1px 0 0;
    border-style: solid;
  }

  flex-shrink: 1;
}

.dropdown {
  background-color: transparent;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dropdown-open {
  p {
    max-height: 300px;
    padding-top: 5px;

    @media (max-width: 880px) {
      max-height: 1000px;
    }
  }
}

.dropdown-closed {
  p {
    max-height: 0;
  }
}

.dropdown-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.dropdown-content {
  p {
    font-size: 14px;
    overflow: hidden;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    text-align: left;
  }
}

.craft-trip {
  width: 211px !important;
  height: 56px !important;
  border-radius: 40px !important;
  font-size: 18px !important;
  margin-top: 32px;
  text-decoration: none !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 880px) {
    width: 340px !important;
  }
}
