@import '../../../styles/variables';

.homepage-qa {
  background-color: $color-light-purple;
  background-image: url(../../../public/img/homepage/globe-go-elsewhere.svg);
  background-position: center 20%;
  background-repeat: no-repeat;
  padding: 45px 0 65px;
  text-align: center;
  position: relative;
  z-index: 0;

  h3.h2-q-a {
    @media #{$breakpoint-md-up} {
      margin-bottom: 0;
    }
  }

  :global a.homepage-qa__btn {
    transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    padding: 3px;
    text-decoration: none;

    .btn__inner {
      background-color: $color-light-purple;
      display: inline-grid;
      grid-auto-flow: column;
      grid-gap: 8px;
      align-items: center;
      padding-left: 45px;
      padding-right: 45px;
    }

    &:hover {
      box-shadow: 4px 4px 20px -12px rgb(113 111 128 / 50%);
    }
  }

  @media #{$breakpoint-md-up} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 72px 0;
  }
  
  @media #{$breakpoint-xs-only} {
    background-size: 85%;
  }
}
