@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1600px;

.video {
  &__frame {
    position: relative;
    padding-bottom: 62%;
    width: 100%;
    overflow: hidden;
    border-radius: 16px;

    @media (min-width: $sm) {
      border-radius: 20px;
    }
  }

  &__frame:hover {
    @media (min-width: $sm) {
      outline: 3px solid $color-primary-purple;
      transition: outline 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  &__inner-frame {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    overflow: hidden;
  }

  &__vimeo {
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__title {
    width: 50%;
    height: 100%;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    padding-right: 6%;
    padding-left: calc(27px + 6%);

    @media (min-width: $sm) {
      padding-right: 3%;
      padding-left: calc(27px + 3%);
    }

    @media (min-width: $xl) {
      padding-right: 6%;
      padding-left: calc(33px + 6%);
    }

    &__text {
      width: 100%;
      text-align: left;
      line-height: 25px !important;
      font-size: 20px !important;

      @media (min-width: $xl) {
        font-size: 24px !important;
        line-height: 30px !important;
      }
    }
  }

  &__button {
    position: absolute;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    @media (min-width: $xl) {
      height: 66px;
      width: 66px;
    }

    &__svg {
      &-play {
        height: 28px;
        width: 28px;

        @media (min-width: $xl) {
          height: 35px;
          width: 35px;
        }
      }

      &-pause {
        height: 36px;
        width: 36px;

        @media (min-width: $xl) {
          height: 42px;
          width: 42px;
        }
      }
    }
  }
}
