@import 'styles/variables';

.process-container {
  margin-top: 52px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 72px;
  padding-bottom: 74px;

  @media (max-width: 880px) {
    padding-bottom: 100px;
  }
}

.panel-containers {
  margin: 0 32px;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  column-gap: 72px;

  @media (max-width: 880px) {
    flex-direction: column;
    align-items: center;
  }
}

.tabs-container {
  display: flex;
  flex-direction: column;
  max-width: 386px;
  min-width: 386px;
  width: 100%;
  flex-shrink: 0;
  padding-bottom: 32px;

  @media (max-width: 880px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.tabs-container {
  row-gap: 16px;
}

.tab-button {
  background-color: transparent !important;
  border-radius: 40px;
  height: 64px;
  color: $color-gray-7;
  font-size: 24px;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  position: relative;
  padding: 0 !important;

  @media (max-width: 880px) {
    color: $color-gray-8;
    background-color: white !important;
    box-shadow: 0px 6px 16px #6c5ac614;
  }
}

.tab-button::before {
  color: $color-gray-7;

  @media (max-width: 880px) {
    content: url('/icons/arrow-right-gradient-thick.svg');
    z-index: 99;
    position: absolute;
    right: 28px;
    rotate: 90deg;
    padding-top: 0px;
    padding-left: 4px;
  }
}

.tab-button-active {
  color: $color-gray-8 !important;
  background-color: white !important;
  box-shadow: 0px 6px 16px #6c5ac614;

  &::before {
    content: url('/icons/arrow-right-gradient-thick.svg');
    z-index: 99;
    position: absolute;
    right: 24px;
    padding-top: 4px;

    @media (max-width: 880px) {
      transform: rotate(180deg);
      padding-right: 4px;
      padding-top: 0px;
      padding-left: 0px;
    }
  }
}

.tab-button-text {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 22px;
  margin-left: 22px;

  p {
    margin-bottom: 0px !important;
    font-size: 38px;
    font-weight: 800;
  }
}

.tab-button-number {
  background: linear-gradient(45deg, #324bf9 11%, #9409ee 84%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 3px;
}

.tab-button-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.craft-trip {
  width: 211px !important;
  height: 56px !important;
  border-radius: 40px !important;
  font-size: 18px !important;
  text-decoration: none !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 880px) {
    width: 340px !important;
    margin: auto;
  }
}

.right-panel {
  flex-shrink: 1;

  @media (max-width: 880px) {
    display: none;
  }
}

.right-panel-header {
  font-size: 20px !important;
}

.right-panel-text {
  ul {
    list-style-type: none !important;
    padding-left: 0px !important;
  }

  li {
    display: flex;
    align-items: flex-start;
    column-gap: 8px;
    padding-top: 16px;
  }

  p {
    color: $color-gray-7;
    font-size: 16px;
  }

  h3 {
    margin-bottom: 0px !important;
    margin-top: 32px !important;
    padding-bottom: 4px;
  }
}

.mobile-panel-text {
  font-size: 16px !important;
  font-weight: 500;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ul {
    list-style-type: none !important;
    padding-left: 0px !important;
  }

  li {
    display: flex;
    align-items: flex-start;
    column-gap: 8px;
    padding-top: 16px;
  }

  p {
    color: $color-gray-7;
    font-size: 16px;
  }

  h3 {
    margin-bottom: 0px !important;
    margin-top: 32px !important;
    padding-bottom: 4px;
  }

  @media (min-width: 880px) {
    display: none;
  }
}

.mobile-panel-text-selected {
  max-height: 500px;
}
