@import 'styles/variables';

.hiw-container {
  background-color: #f5f0ff;
  width: 100%;
  border-radius: 20px;
  padding: 64px 0 0 0;

  @media (min-width: 1525px) {
    padding: 64px 0;
  }

  @media (max-width: 880px) {
    max-width: 100vw;
    overflow-x: hidden;
    border-radius: 0;
    padding-top: 32px;
  }
}

.hiw-headline {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  h2 {
    font-size: 32px !important;
    color: $color-gray-8;
  }
}

.hiw-tabs-container {
  display: flex;
  justify-content: center;
  justify-items: center;
  column-gap: 10px;
  width: 100%;
}

.hiw-button-overwrite {
  padding: 0;
  background-color: #f5f0ff;
}

.hiw-tab {
  width: 140px;
  height: 34px;
  font-weight: 600;
  color: $color-gray-7;
  border: 1px solid #e4d9fb;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  justify-items: center;
  line-height: 2rem;
  background-color: #f5f0ff;
  padding: 0;

  @media (max-width: 880px) {
    font-size: 14px;
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    white-space: nowrap;
  }
}

.hiw-tab-selected {
  background-image: linear-gradient(45deg, #324bf9 11%, #9409ee 84%);
  background-color: #f5f0ff;
  border: none;
  border-radius: 26px;

  @media (min-width: 880px) {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.hiw-tab-text-selected {
  background-color: #f5f0ff;
  margin: auto;
  height: 30px;
  border: none;
  align-self: center;

  @media (max-width: 880px) {
    font-size: 14px;
    width: calc(100% - 4px);
  }
}
