@import 'styles/variables';

/*
  There's a lot of bicycle code,
  the customer decided to play in perfect pixel
  related to their own landing page
*/

.homepage-hero {
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  min-height: 0;
  padding-top: 80px;

  :global .hero {
    min-height: auto!important;
    position: relative;
  }

  :global .scroll-lead {
    display: none;

    @media #{$breakpoint-md-up} {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap:8px;
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
      z-index: 10;
    }

    .scroll-lead-line {
      height: 32px;
      width: 1px;
      background-color: #fff;
    }
  }

  :global .homepage-hero__radius {
    border-radius: 20px;

    @media #{$webflow-xl-breakpoint} {
      border-radius: 27px;
    }
  }

  :global .homepage-hero__container {
    position: relative;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 70px;
    
  }

  :global .homepage-hero__video {
    position: relative;
    display: flex;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
    }

    :global .homepage-hero-overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 20%);
    }
  }

  :global .hero-title__section {
    margin-top: -20px !important;

    @media #{$webflow-lg-breakpoint} {
      margin-top: -22px !important;
    }
  }

  :global .hero-title {
    font-size: 32px;
    line-height: 35px;

    h1 {
      margin-bottom: 10px;
      text-shadow: 0 3px 12px #352F4180;

      @media #{$webflow-lg-breakpoint} {
        font-size: 51px;
        line-height: 56px;
        
      }
    }

    h4 {
      font-size: 16px;
      line-height: 23px;
      text-shadow: 0 3px 12px #352F4180;

      @media #{$webflow-lg-breakpoint} {
        font-size: 20px;
        line-height: 28px;
      }
    }

    @media #{$breakpoint-md-up} {
      font-size: 46px;
      line-height: 56px;
    }
  }

  :global .hero-subtitle {
    font-size: 14px;
    
    font-weight: 500;

    @media #{$breakpoint-md-up} {
      font-size: 16px;
      line-height: 23px;
    }

    @media #{$breakpoint-lg-up} {
      font-size: 18px;
    }
  }

  :global .hero-search.v0 {
    width: 386px;
    margin: 40px auto;

    @media #{$breakpoint-xl-up} {
      width: 425px;
    }

    @media #{$breakpoint-xs-only} {
      width: 265px;
    }

    .hero-submit {
      flex: 1;
    }
  }

  :global .homepage-hero__select {
    margin: 0 !important;
    padding: 0 !important;
    height: unset !important;

    > div {
      margin-top: 0;
    }

    .select__control {
      background-color: $color-white;
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .select__placeholder {
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      color: #342f41;
      left: 21px;

      @media #{$breakpoint-xs-only} {
        left: 14px;
      }

      @media #{$breakpoint-sm-up} {
        font-size: initial;
      }
    }

    .select__control--is-focused,
    .select__value-container--has-value {
      .select__placeholder {
        display: none;
        transition: 0s all;
      }
    }

    .select__value-container {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 48px;
    }
  }

  @media screen and (max-width: 991px) {
    height: 710px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 68px;
  }

  @media screen and (max-width: 479px) {
    overflow: hidden;
    height: 600px;
    min-height: 550px;
    padding-top: 62px;
  }

  @media #{$breakpoint-md-up} {
    margin-top: -20px;
  }
}

.homepage-hero {
  :global .hero-title {
    h1 {
      @media #{$webflow-lg-breakpoint} {
        font-size: 46px;
      }
    }

    h4 {
      font-size: 18px;

      @media #{$webflow-lg-breakpoint} {
        font-size: 32px;
      }
    }
  }

  :global .hero-subtitle {
    font-size: 18px;

    @media #{$breakpoint-xxs-up} {
      font-size: 18px !important;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$breakpoint-md-up} {
      font-size: 22px !important;
    }

    @media #{$breakpoint-lg-up} {
      font-size: 22px !important;
    }
  }

  :global .hero-search.v1 {

    .hero-form {
      justify-content: start;
    }

    width: 432px;
    margin: 0 auto;
    margin-top: 40px;

    @media #{$breakpoint-xs-only} {
      width: 300px;
    }

    .select__menu {
      margin-left: -50px;
      width: 428px !important;

      @media #{$breakpoint-xs-only} {
        margin-left: -31px;
        width: 300px !important;
      }
    }
  }

  :global .hero-search.v1 .homepage-hero__select {
    .select__placeholder {
      color: #8a8597;
    }
  }
}

.how-it-works-button {
  @media (min-width: 880px) {
    display: none !important;
  }
}
