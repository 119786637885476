@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1600px;

.modal-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 1001;
  background-color: $color-pop-up;
}

.modal {
  &__wrap {
    position: relative;
    margin: auto;
    width: 100%;
    border: 1px solid transparent;

    &:focus {
      outline: none;
    }

    @media (min-width: $sm) {
      width: calc(100% - 20px);
      max-width: 736px;
    }

    @media (min-width: $lg) {
      max-width: 900px;
    }

    @media (min-width: $xl) {
      max-width: 1000px;
    }
  }

  &__body {
    max-height: 80vh;
    overflow: auto;
    background-color: $color-white;
    box-shadow: 0 0 24px #4f398a5c;

    @media (min-width: $sm) {
      border-radius: 20px;
    }
  }

  &__close-btn {
    position: absolute;
    top: -40px;
    right: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    border: 0;
    background-color: white;
    color: $color-dark-grey;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @media (min-width: $sm) {
      top: 0;
      right: -50px;
    }

    &:focus {
      outline: none;
    }
  }
}
