@import 'styles/variables';

$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;

.local-experts {
  margin-top: 16px;

  @media screen and (min-width: $xl) {
    margin: 32px 0;
  }

  :global {
    .local-experts {
      &__intro-title {
        text-align: center;
        margin-bottom: 16x !important;

        @media screen and (max-width: 767px) {
          font-size: 28px !important;
          line-height: 34px;
        }
      }

      &__intro-text {
        text-align: center;
        font-weight: normal !important;

        @media screen and (max-width: 767px) {
          padding-left: 25px;
          padding-right: 25px;
          text-align: center;
          font-size: 16px !important;
        }
      }
    }
  }
}

.embla {
  margin: 0 auto;
  margin-top: -20px;
  margin-bottom: 16px;
  height: 500px;
  position: relative;

  @media screen and (max-width: 767px) {
    height: 400px;
  }

  @media (min-width: $sm) {
    margin-bottom: 64px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    height: 600px;
  }
}
.embla__viewport {
  overflow: hidden;
  margin-top: 50px;
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;

  grid-column-gap: 1px;
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: end;
  gap: 1.2rem;
  margin-top: 1.8rem;
}

.button-wrapper {
  background-color: transparent;
  border: 1px solid #c0bacc;
  border-radius: 50%;

  padding: 10px;
  width: 45px;
  height: 45px;
  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
}

.embla__buttons {
  position: absolute;
  top: -36px;
  right: 32px;

  z-index: 1;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;

  button {
    background-color: transparent;
  }
}

.embla__button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20vw;

  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;

  z-index: 1;
}

.embla__button:disabled {
  cursor: pointer !important;
}

.embla__button__svg {
  height: 12px;
  width: 12px;
}

.expert,
.expert-active {
  display: grid;
  grid-template-rows: 1fr;
  border-radius: 20px;
}

.expert {
  width: 320px;
  height: 480px;

  @media screen and (max-width: 767px) {
    width: 260px;
    height: 390px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 400px;
    height: 600px;
  }

  :global {
    .expert-content {
      opacity: 0;
      transition: opacity 0.8s ease-in-out;
    }
  }
}

.expert-active {
  :global {
    .expert-content {
      opacity: 1;
    }
  }
}

.play-video-btn:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-out;
  background-color: red;
}

.pause-video-btn:hover {
  transform: scale(1.6);
  transition: all 0.2s ease-out;
  background-color: red;
}

.pause-video-btn {
  position: relative;
  bottom: 250px;
  left: 45%;
  border-radius: 50%;
  width: 32px;
  transform: scale(1.5);

  img {
    width: 24px;
    margin-top: -1px;
  }
}

.expert-content {
  grid-template-rows: auto auto auto 40px auto;
  text-align: center;
  align-self: end;

  p {
    margin: 0 !important;
  }

  > :nth-child(1) {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin: 0 auto;

    img {
      width: 24px;
      margin-top: -1px;
    }
  }

  // Styling for the name section
  > :nth-child(2) {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    color: white;
    padding-top: 10px;

    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }

  // Styling for the local expert section
  > :nth-child(3) {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: bold;
    color: white;
    padding-top: 10px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  // Styling for the best experience planned section
  > :nth-child(4) {
    text-align: center;
    color: white;
    font: normal normal normal 14px/22px Geomanist;
    font-family: 'Geomanist webfont', sans-serif;
    opacity: 0.7;
    padding-top: 15px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  // Styling for the best experience planned details section
  > :nth-child(5) {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    color: white;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 20px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.expert__destination {
  margin-left: 4px;
  text-decoration: underline;
}
