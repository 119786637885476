@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1600px;

.hiw-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: $sm) {
    margin: auto;
    gap: 48px;
  }

  @media (min-width: $xxl) {
    padding: 0;
  }

  @media (max-width: $md) {
    border-radius: 0;
    margin-bottom: 32px;
  }

  &__background-gradient {
    @media (min-width: $sm) {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      transform: translate(30%, -20%) scale(60%);
      background-image: url('/img/homepage/howItWorks/gradient-bg.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.craft-trip {
  width: 211px !important;
  height: 56px !important;
  border-radius: 40px !important;
  font-size: 18px !important;
  text-decoration: none !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $sm) {
    display: none;
  }

  @media (max-width: $md) {
    width: 340px !important;
    margin: auto;
  }
}
