@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1600px;

.itineraries {
  position: relative;
  height: 570px;
  margin-bottom: 15%;
  @media screen and (min-width: $sm) {
    margin-bottom: 5%;
    height: 400px;
  }
  @media screen and (min-width: $lg) {
    height: 490px;
  }
  @media screen and (min-width: $xl) {
    height: 520px;
  }

  h2 {
    color: white;
  }
  h5 {
    color: white !important;
    opacity: 70%;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    margin-bottom: 5px!important;
  }
  p {
    color: white;
  }

  :global {
    .itineraries {
      &__in-page-hero {
        left: 0;
        right: 0;
        height: 100%;
        position: absolute;
        padding: 0 50px;
        background-size: auto, cover;
        background-position: center center;

        &--active {
          animation: fadeIn 0.6s;
        }

        &--previous {
          animation: fadeOut 0.6s;
        }
      }

      &__content {
        position: absolute;
        z-index: 4;
        display: flex;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 40px 5% 0 5%;
        @media screen and (min-width: $sm) {
          display: grid;
          gap: initial;
          grid-template-columns: 66% 33%;
          grid-template-rows: 100%;
          padding: 0 5%;
        }
        @media screen and (min-width: $lg) {
          grid-template-columns: 50% 50%;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 0 10px;
        @media screen and (min-width: $sm) {
          padding: 0 5%;
          text-align: left;
        }
      }

      &__frame-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
      }

      &__frame {
        opacity: 0;
        position: absolute;
        max-width: 822px;
        overflow: hidden;
        outline: 1px solid #ffffffb3;
        border-radius: 10px;
        padding: 0;
        background-color: transparent;
        box-shadow: 0px 6px 20px #4f398a33;
        height: 110%;
        transition: all .3s ease-in-out;
        @media screen and (min-width: $sm) {
          right: 1%;
          top: 20%;
          height: 85%;
          border-radius: 16px;
          &:hover {
            outline: 2px solid #fff;
            box-shadow: 0px 16px 24px #4f398a5c;
          }
        }
        @media screen and (min-width: $xxl) {
          top: 10%;
          height: 98%;
        }

        &--active {
          opacity: 0;
          z-index: 1;
          animation: slideUpFadeIn 0.6s forwards ease-out;
        }

        &--previous {
          opacity: 1;
          animation: slideUpFadeOut 0.4s forwards ease-out;
          pointer-events: none;
        }
      }

      &__frame-scroll {
        &--0 {
          animation: partial-scroll-0 7s 1s ease infinite;
        }
        &--1 {
          animation: partial-scroll-1 7s 1s ease-in-out infinite;
        }
        &--2 {
          animation: partial-scroll-2 7s 1s ease-in-out infinite;
        }
        &--3 {
          animation: partial-scroll-3 7s 1s ease-in-out infinite;
        }
        &--4 {
          animation: partial-scroll-4 7s 1s ease-in-out infinite;
        }
      }

      &__nav {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        margin-top: 10px;
        @media screen and (min-width: $sm) {
          align-items: flex-start;
        }
        @media screen and (min-width: $md) {
          align-items: center;
          flex-direction: row;
          gap: 5%;
          margin-top: 24px;
        }
      }

      &__nav-button {
        border: 1px solid white;
        border-radius: 32px;
        background-color: white;
        height: 48px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        text-decoration: none !important;
        font-weight: 600;
        width: 100%;
        transition: all .2s ease-in-out;

        white-space: nowrap;

        @media screen and (min-width: $sm) {
          padding: 0 25px;
          justify-content: space-between;
          gap: 16px;
          width: autp;
          max-width: 342px;
        }

        &:hover {
          background-color: $color-gray-3;
          border-color: $color-gray-3;
        }
      }

      &__nav-button-text {
        @media screen and (max-width: $sm) {
          width: 100%;
          text-align: center;
        }
      }

      &__nav-dot {
        height: 8px;
        width: 8px;
        padding: 0;
        background-color: white;
        opacity: 40%;
        border-radius: 50%;
        margin: 0 4px;
        @media screen and (min-width: $sm) {
          height: 10px;
          width: 10px;
          margin-right: 8px;
        }

        &--active {
          background-color: white;
          opacity: 100%;
        }
      }
    }
  }
}
