@import 'styles/variables';

.calculator-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -30px;
}
.form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.calc-input {
    border-radius: 10px;
    width: 133px;
    height: 48px;
    text-align: center;
    border-width: 1px;
    border-color: #C0BACC;
    border-style: solid;
    font-size: 20px;
    font-weight: 600;
}
.calc-input::placeholder {
    color: $color-gray-5;
    font-size: 20px;
    font-weight: 600;
    opacity: 1; /* Firefox */
  }
.calc-input-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 14px;
}
.equal-sign {
    font-size: 35px !important;
    font-weight: 100;
    color: $color-gray-5;
    margin-bottom: 0 !important;
    align-self: center;
    transform: translateY(-2px);
}
.calc-result {
    font-weight: 800;
    font-size: 20px;
}
.cost-of-research {
    font-size: 20px;
    font-weight: 600;
    color: $color-gray-7;
    margin-bottom: 0 !important;
}