@import 'styles/variables';

.price-widget-container {
  display: flex;
  justify-content: space-between;
  max-width: 1080px;
  margin: auto;
  align-items: flex-start;
  column-gap: 80px;
  padding: 0 32px;

  @media (max-width: 880px) {
    flex-direction: column;
  }
}

.price-widget-input {
  max-width: 302px;
  flex-shrink: 0;

  h3 {
    font-size: 24px !important;
  }

  h4 {
    margin-top: 20px !important;
  }

  @media (max-width: 880px) {
    max-width: none;
    width: 100%;
  }
}

.price-widget-output {
  @media (max-width: 880px) {
    margin-top: 0;
  }
}

.slider-container {
  div {
    padding-top: 0;

    > span {
      > span {
        > div {
          > div {
            > p {
              padding-top: 9px;
              padding-bottom: 2px;
              margin-bottom: 0px !important;
            }
          }
        }
      }
    }
    > div {
      > div {
        h5 {
          @media (max-width: 880px) {
            font-size: 20px !important;
            font-weight: 700 !important;
          }
        }
      }
    }
  }

  @media (max-width: 880px) {
    margin-top: 0;
    padding-bottom: 10px;
  }
}

.slider-blurb {
  margin-bottom: 10px;

  @media (max-width: 880px) {
    margin-bottom: 0;
  }
}

.blurb-1 {
  font-size: 14px;

  @media (max-width: 880px) {
    font-weight: 600 !important;
  }
}

.blurb-2 {
  font-size: 14px;
  color: $color-gray-6;
}

.flight-blurb {
  font-size: 14px;
  color: $color-gray-6;
}

.slider-inputs-wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;

  @media (max-width: 880px) {
    margin-top: 0;
  }
}

.travelers-input {
  flex-grow: 1;
}

.days-input {
  flex-grow: 1;
}

.input-buttons {
  border: 1px solid #c0bacc;
  border-radius: 10px;
  height: auto;
  padding: 4px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 48px;
    height: 32px;
    display: inline-block;
    background-color: transparent;
    border-radius: 50%;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.input-numbers {
  margin-bottom: 0 !important;
  font-size: 16px !important;
  font-weight: 600;
}

.inputs-flavor-text {
  font-size: 14px;
  color: $color-gray-6;

  @media (max-width: 880px) {
    display: none;
  }
}

.dropdown {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: 1px;
  border-color: $color-gray-5;
  border-style: solid;
  padding-left: 10px;
  font-size: 14px !important;
  font-weight: 600;
  background-color: white;
}

.dropdown-wrapper {
  width: 100%;
  border-radius: 10px;
  border: 1px;
  border-color: $color-gray-5;
  position: relative;
}

.dropdown-wrapper::before {
  content: url('/icons/magnifying-glass.svg');
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 25%;
}
