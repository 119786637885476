@import 'styles/variables';
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;

.feel-good-trips {
  margin-top: 32px;

  @media screen and (min-width: $lg) {
    margin-top: 50px;
  }

  :global {
    .feel-good-trips {
      &__intro-title {
        text-align: center;
        margin-bottom: 12px !important;
      }

      &__intro-text {
        text-align: center;
        font-weight: normal !important;
      }

      &__body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media screen and (min-width: $lg) {
          flex-direction: row;
          gap: 72px;
          margin-top: 40px;
        }
      }

      &__body-text {
        max-width: 560px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $lg) {
          gap: 72px;
        }

        h6 {
          @media screen and (min-width: $xl) {
            line-height: 28px;
          }
        }

        h3 {
          width: 100%;
        }
      }

      &__body-column {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media screen and (min-width: $lg) {
          align-items: start;
        }
      }

      &__circle {
        position: relative;
        display: flex;
        align-items: center;
        margin: 24px !important;
      }

      &__circle-text {
        width: 100%;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        position: absolute;
        text-align: center;
        z-index: 1;
        font-size: 18px;
        bottom: 8%;
        line-height: 24px;
        padding: 0 20px;
      }

      &__ribbon {
        position: relative;
      }

      &__ribbon-text-top,
      &__ribbon-text-bottom {
        width: 100%;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        position: absolute;
        text-align: center;
        z-index: 1;
        font-size: 22px;
        line-height: 32px;
        text-shadow: 0 3px 12px rgba(53, 47, 65, 0.8);
      }

      &__ribbon-text-top {
        top: 38%;
      }

      &__ribbon-text-bottom {
        top: 56%;
        line-height: 28px;
      }
    }
  }
}
